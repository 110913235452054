import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function ProductTabs({handleChange2,value}) {

  



  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange2}
        
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="Create Product" />
        <Tab value="two" label="Create Sub-Product/Inventory" />
        
      </Tabs>
    </Box>
  );
}