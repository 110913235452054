import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, TextField, Grid, FormControl, useTheme, Select } from "@mui/material";

import { useForm } from "react-hook-form";
import { tokens } from "../theme";
import MyDialog from "../components/MyDialog";
import ErrorMSG from "../components/ErrorMSG";
import { styles } from "../utils/custom";

export default function CategoryAdd({ open, handleClose,type }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // main state to store the payload
  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm({
    defaultValues: {
      name: "",
      status: true,
      type: "",
      categoryId: "",
      taxId: []
    }
  });

  console.log(open, "open");

  const onSubmit = async (data) => {};

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">{type==="category"?"Add Category":"Add Tax"}</DialogTitle>
          <DialogContent>
            {
              type === "category" ? <Box m="10px">
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-6">
                      Select Category <span className="danger mr-0">*</span>
                    </label>
                    <Select
                      styles={styles}
                      defaultValue={""}
                      value={""}
                      size="small"
                      theme={(x) => ({ ...x, borderRadius: 4 })}
                      className="react-select-no-outline reactSelectFocus z-index-5"
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder="Select Vendor"
                      noOptionsMessage={() => "Select Vendor"}
                      onChange={(e) => {
                        // onChange(e);
                      }}
                      //   options={vendorsOption}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Category Name <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("name", { required: true })}
                    />
                    {errors.name && errors.name.type === "required" && <ErrorMSG text="Category Name is required" />}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-6">
                      Select Tax <span className="danger mr-0">*</span>
                    </label>
                    <Select
                      styles={styles}
                      defaultValue={""}
                      value={""}
                      size="small"
                      theme={(x) => ({ ...x, borderRadius: 4 })}
                      className="react-select-no-outline reactSelectFocus z-index-5"
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder="Select Status"
                      noOptionsMessage={() => "Select Status"}
                      onChange={(e) => {
                        // onChange(e);
                      }}
                      //   options={vendorsOption}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-6">
                      Select Status <span className="danger mr-0">*</span>
                    </label>
                    <Select
                      styles={styles}
                      defaultValue={""}
                      value={""}
                      size="small"
                      theme={(x) => ({ ...x, borderRadius: 4 })}
                      className="react-select-no-outline reactSelectFocus z-index-5"
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder="Select Status"
                      noOptionsMessage={() => "Select Status"}
                      onChange={(e) => {
                        // onChange(e);
                      }}
                      //   options={vendorsOption}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>: <Box m="10px">
              <Grid container spacing={3}>
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Tax Name <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("name", { required: true })}
                    />
                    {errors.name && errors.name.type === "required" && <ErrorMSG text="Category Name is required" />}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Tax Percentage <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("name", { required: true })}
                    />
                    {errors.name && errors.name.type === "required" && <ErrorMSG text="Category Name is required" />}
                  </FormControl>
                </Grid>
               
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-6">
                      Select Status <span className="danger mr-0">*</span>
                    </label>
                    <Select
                      styles={styles}
                      defaultValue={""}
                      value={""}
                      size="small"
                      theme={(x) => ({ ...x, borderRadius: 4 })}
                      className="react-select-no-outline reactSelectFocus z-index-5"
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder="Select Status"
                      noOptionsMessage={() => "Select Status"}
                      onChange={(e) => {
                        // onChange(e);
                      }}
                      //   options={vendorsOption}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            }
           
           
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              // onClick={() => setContinues(false)}
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
