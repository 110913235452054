import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { OrderMap } from "./type";

//fetch order
export const fetchOrders = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.ORDER_REQUEST });
    let apiEndpoint = `${API_END_POINTS.orders}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.ORDER_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.ORDER_FAIL, error: message });
  }
};

//Add order
export const createOrder = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.ORDER_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addOrder}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.ORDER_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.ORDER_ADD_FAIL, error: message });
  }
};

//Edit order
export const updateOrder = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.ORDER_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editOrder}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: OrderMap.ORDER_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.ORDER_UPDATE_FAIL, error: message });
  }
};

//Delete order
export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.ORDER_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteOrder}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: OrderMap.ORDER_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: OrderMap.ORDER_DELETE_FAIL, error: message });
  }
};

//single order
export const ViewOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.SINGLE_ORDER_REQUEST });
    let apiEndpoint = `${API_END_POINTS.singleOrder}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}/view`);
    return dispatch({
      type: OrderMap.SINGLE_ORDER_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: OrderMap.SINGLE_ORDER_FAIL, error: message });
  }
};

//Edit order Status
export const updateOrderStatus = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.ORDER_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateOrderStatus}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: OrderMap.ORDER_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.ORDER_UPDATE_FAIL, error: message });
  }
};

//Edit order Status
export const customerDropdown = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.CUSTOMER_DROPDOWN_REQUEST });
    let apiEndpoint = `${API_END_POINTS.customerDropdown}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.CUSTOMER_DROPDOWN_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.CUSTOMER_DROPDOWN_FAIL, error: message });
  }
};

//find Inventory
export const findInventory = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.FIND_INVENTORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.findInventory}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.FIND_INVENTORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: OrderMap.FIND_INVENTORY_FAIL, error: message });
  }
};

//search Inventory
export const searchInventory = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.SEARCH_INVENTORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.searchInventory}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.SEARCH_INVENTORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: OrderMap.SEARCH_INVENTORY_FAIL, error: message });
  }
};

//Add Payment
export const addPayment = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.PAYMENT_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addPayment}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.PAYMENT_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.PAYMENT_ADD_FAIL, error: message });
  }
};

//single order
export const viewPDFData = (id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.PDF_GET_REQUEST });
    let apiEndpoint = `${API_END_POINTS.pdfView}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}/pdf`);
    return dispatch({
      type: OrderMap.PDF_GET_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: OrderMap.PDF_GET_REQUEST, error: message });
  }
};

//Add Payment
export const editPayment = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.PAYMENT_EDIT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editPayment}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: OrderMap.PAYMENT_EDIT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.PAYMENT_EDIT_FAIL, error: message });
  }
};

//clear Payment
export const clearPayment = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.PAYMENT_CLEAR_REQUEST });
    let apiEndpoint = `${API_END_POINTS.clearPayment}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.PAYMENT_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: OrderMap.PAYMENT_CLEAR_FAIL, error: message });
  }
};

//Fedex addShipments
export const addShipments = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.FEDEX_ADD_SHIPMENT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addShipments}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.FEDEX_ADD_SHIPMENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error?.data?.message ? error?.data?.message : error?.message;
    toast.error(message);
    return dispatch({ type: OrderMap.FEDEX_ADD_SHIPMENT_FAIL, error: message });
  }
};

//Fedex getQuotes
export const getQuotes = (body) => async (dispatch) => {
  try {
    dispatch({ type: OrderMap.FEDEX_GET_QUOTES_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getQuotes}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: OrderMap.FEDEX_GET_QUOTES_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error?.data?.message ? error?.data?.message : error?.message;
    toast.error(message);
    return dispatch({ type: OrderMap.FEDEX_GET_QUOTES_FAIL, error: message });
  }
};
