import { LayoutMap } from "./type";

// category intial state
const initialToggleState = {
  toggleState: false,
  notification: null,
  address: null
};

export const reducer = (state = initialToggleState, action) => {
  switch (action.type) {
    case LayoutMap.GET_TOGGLE_STATE: {
      const toggleState = action.payload;
      return {
        ...state,
        toggleState: toggleState,
        isLoading: false
      };
    }
    case LayoutMap.GET_NOTIFICATION: {
      const toggleState = action.payload.data;
      return {
        ...state,
        notification: toggleState,
        isLoading: false
      };
    }
    case LayoutMap.ADDRESS_REQUEST:
      return { ...state, isLoading: true };
    case LayoutMap.ADDRESS_SUCCESS:
      return { ...state, isLoading: false, address: action.payload.data };
    case LayoutMap.ADDRESS_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
