import { LayoutMap } from "./type";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor from "../../../utils/JsonInterceptor";
import { toast } from "react-toastify";

// get toggle state
export const getToggleState = (state) => async (dispatch) => {
  return dispatch({
    type: LayoutMap.GET_TOGGLE_STATE,
    payload: state
  });
};

export const getNotification = () => async (dispatch) => {
  let apiEndpoint = `${API_END_POINTS.fetchNotification}`;
  const response = await JsonInterceptor.get(`${apiEndpoint}`);
  var res = response.data; //useEncryption(response.data);
  return dispatch({
    type: LayoutMap.GET_NOTIFICATION,
    payload: res
  });
};

//Get Address
export const getAdminAddress = () => async (dispatch) => {
  try {
    dispatch({ type: LayoutMap.ADDRESS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getAdminAddress}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: LayoutMap.ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(message);
    return dispatch({ type: LayoutMap.ADDRESS_FAIL, error: message });
  }
};
