import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import React from "react";
// import Header from "../components/Header";
import { tokens } from "../theme";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import ImgUpload from "../media/Images/no-photo-available.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import AddIcon from "@mui/icons-material/Add";
import ProductTabs from "./ProductTabs";
import { styled } from "@mui/material/styles";
import JoditEditor from "jodit-react";
import { styles } from "../utils/custom";
import CategoryAdd from "./CategoryAddModal";
import { InventoryAdd } from "./InventoryAdd";

export default function ProductDesign() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState("");
  const [type, setType] = React.useState("category");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const editor = React.useRef(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Long Description",
    height: 210,
    allowResizeX: false,
    allowResizeY: false,
    limitWords: 10000,
    limitHTML: true
    // removeButtons: ["bold"]
  };

  const [value, setValue] = React.useState("one");
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const vendorsOption = [
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Inctive",
      value: "inactive"
    }
  ];

  return (
    <Box mx="20px">
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box  sx={{ mb: 2 }}>
            <Typography
              variant="h3"
              color={colors.themeSecondaryBlueColor[100]}
              fontWeight="normal"
              sx={{ m: "0 0 5px 0" }}
            >
              Add Product
            </Typography>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="product-card">
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <AttachFileIcon />
                  </IconButton>
                }
                title="Product Image"
              />
              <CardMedia className="temp" component="img" height="108" image={ImgUpload} alt="Paella dish" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Only *.png, *.jpg and *.jpeg image files are accepted
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="product-card">
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <AddIcon
                      className="icon-check"
                      onClick={() => {
                        setType("category");
                        setOpen(!open);
                      }}
                    />
                  </IconButton>
                }
                title="Add/Select Category"
              />
              <CardContent>
                <FormControl fullWidth>
                  <label className="react-select-labels z-index-6">
                    Select Category <span className="danger mr-0">*</span>
                  </label>
                  <Select
                    styles={styles}
                    defaultValue={value}
                    value={value}
                    size="small"
                    theme={(x) => ({ ...x, borderRadius: 4 })}
                    className="react-select-no-outline reactSelectFocus z-index-5"
                    classNamePrefix="react-select"
                    isClearable={true}
                    placeholder="Select Vendor"
                    noOptionsMessage={() => "Select Vendor"}
                    onChange={(e) => {}}
                    isSearchable={true}
                  />
                </FormControl>
                {/* <Button
                  component="label"
                  fullWidth
                  sx={{ mt: 1 }}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    console.log("testing.................");
                    setType("category");
                    setOpen(!open);
                  }}
                >
                  Create Category
                </Button> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="product-card">
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <Brightness1Icon color="success" sx={{ fontSize: 15 }} />
                  </IconButton>
                }
                title="Product Status"
              />

              <CardContent>
                <FormControl fullWidth>
                  <label className="react-select-labels z-index-6">
                    Select Status <span className="danger mr-0">*</span>
                  </label>
                  <Select
                    styles={styles}
                    defaultValue={value}
                    value={"active"}
                    size="small"
                    theme={(x) => ({ ...x, borderRadius: 4 })}
                    className="react-select-no-outline reactSelectFocus z-index-5"
                    classNamePrefix="react-select"
                    isClearable={true}
                    placeholder="Select Vendor"
                    noOptionsMessage={() => "Select Vendor"}
                    onChange={(e) => {
                      // onChange(e);
                    }}
                    options={vendorsOption}
                    isSearchable={true}
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="product-card">
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <AddIcon
                      className="icon-check"
                      onClick={() => {
                        setType("tax");
                        setOpen(!open);
                      }}
                    />
                  </IconButton>
                }
                title="Add/Select Tax"
              />
              <CardContent>
                <FormControl fullWidth>
                  <label className="react-select-labels z-index-6">
                    Select Tax <span className="danger mr-0">*</span>
                  </label>
                  <Select
                    styles={styles}
                    defaultValue={value}
                    value={value}
                    size="small"
                    theme={(x) => ({ ...x, borderRadius: 4 })}
                    className="react-select-no-outline reactSelectFocus z-index-5"
                    classNamePrefix="react-select"
                    isClearable={true}
                    placeholder="Select Vendor"
                    noOptionsMessage={() => "Select Vendor"}
                    onChange={(e) => {
                      // onChange(e);
                    }}
                    //   options={vendorsOption}
                    isSearchable={true}
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <Box sx={{ pb: 2 }}>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ProductTabs handleChange2={handleChange2} value={value} />
              </Grid>
              {value === "one" ? (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Card className="product-card" sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h4">Create Product</Typography>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          label={
                            <p className="my-0">
                              Product Name <span className="danger mr-0">*</span>
                            </p>
                          }
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          label={
                            <p className="my-0">
                              Short Description <span className="danger mr-0">*</span>
                            </p>
                          }
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <JoditEditor
                          ref={editor}
                          // value={value}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) => {
                            // setContent(newContent);
                            //   onChange(newContent);
                          }} // preferred to use only this option to update the content for performance reasons
                          onChange={(newContent) => {
                            // onChange(newContent);
                          }}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ float: "right" }}>
                          <Button variant="outlined" onClick={() => {}} sx={{ mr: "6px" }}>
                            Close
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={[
                              {
                                "&:hover": {
                                  bgcolor: colors.themeSecondaryBlueColor[100],
                                  color: colors.white[100]
                                }
                              },
                              { bgcolor: colors.themeSecondaryBlueColor[100] }
                            ]}
                          >
                            Save{" "}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ) : (
                <InventoryAdd />
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <CategoryAdd open={open} handleClose={handleClose} type={type} />
    </Box>
  );
}
