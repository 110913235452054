import * as React from 'react';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { Box, StepLabel, Typography } from '@mui/material';

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 300,
    label: '300',
  },
  {
    value: 400,
    label: '400',
  },
  {
    value: 500,
    label: '500',
  },
  // {
  //   value: 800,
  //   label: '800',
  // },
  
  // {
  //   value: 1000,
  //   label: '1000 Max.',
  // },
];

export default function VerticalSlider() {
  return (
    <Stack  spacing={1} direction="column">
     <Typography id="non-linear-slider" gutterBottom>
        Stock Purchase Limit: 
      </Typography>
      <Slider
        getAriaLabel={() => 'Temperature'}
        orientation="horizontal"
        getAriaValueText={valuetext}
        defaultValue={[0, 200]}
        valueLabelDisplay="auto"
        marks={marks}
        max={500}
      />
    </Stack>
  );
}