import { Box, Button, IconButton, TextField } from "@mui/material";
import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InventoryFile from "./InventoryFile";
import AddIcon from "@mui/icons-material/Add";

export function InventoryAdd() {
  const { register, control, handleSubmit, reset, trigger, setError } = useForm({
    // defaultValues: {}; you can populate the fields by this attribute
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "test"
  });

  const appendInventory = () => {
    append({ firstName: "bill", lastName: "luo" });
  };
  const removeInventory = () => {
    append({ firstName: "bill", lastName: "luo" });
  };


  return (
    <Box sx={{ pl: 3,py:3 }}>
      {/* <Box sx={{ float: "right" }}>
        <IconButton onClick={() => appendInventory()}>
          <AddIcon />
        </IconButton>
      </Box> */}
      <InventoryFile appendInventory={appendInventory} />{" "}
      <form onSubmit={handleSubmit((data) => console.log(data))}>
        {fields.map((item, index) => (
          <>
            {/* <Button variant="contained" onClick={() => appendInventory()} sx={{ mr: 1, my: 1 }}>
              <AddIcon />
            </Button>
            <Button variant="outlined" onClick={() => remove(index)}>
              <AddIcon />
            </Button> */}
            <InventoryFile key={item.id} appendInventory={appendInventory}  />
          </>
        ))}
      </form>
    </Box>
  );
}
