import { Box, IconButton, useTheme, Typography, Divider } from "@mui/material";
import { tokens } from "../../theme";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { logout } from "../login/redux/action";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LockIcon from "@mui/icons-material/Lock";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import GetUserRole from "../../utils/GetUserRole";
import { useNavigate } from "react-router-dom";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import MenuIcon from "@mui/icons-material/Menu";
import { getNotification, getToggleState } from "./redux/action";
import TemporaryDrawer from "./TemporaryDrawer";
import LogoutDialog from "./ConfirmPopup";

const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const { notification } = useSelector((state) => state.notification, shallowEqual);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handleClickLogoutOpen = () => {
    setOpenLogout(true);
  };
  const handleLogoutClose = () => {
    setOpenLogout(false);
  };
  // const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
  };
  // const [notificationData, setNotificationData] = React.useState([]);
  React.useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);
  const [toggle, setToggle] = useState(false);
  const onHandleLogout = async () => {
    await dispatch(logout());
    // localStorage.clear();
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2} className="topBar" flexWrap="wrap-reverse" gap="10px">
        {/* SEARCH BAR */}
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap="10px">
          {GetUserRole("order_add") && (
            <>
              <Box className="quickActionBtn" onClick={() => navigate("/orders/create")}>
                <PlaylistAddIcon />
                <Typography variant="span" className="mdHide">
                  Create New Order
                </Typography>
              </Box>
            </>
          )}
        </Box>

        {/* ICONS */}
        <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between" }} className="responsive-head">
          <IconButton
            className="pro-sidebar-toggle-btn"
            onClick={async () => {
              setToggle(!toggle);
              await dispatch(getToggleState(!toggle));
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <IconButton
          onClick={colorMode.toggleColorMode}
          >
            {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton> */}

          {/* Notification Drawer Component */}
          <TemporaryDrawer notificationData={notification} />

          <IconButton onClick={handleClick}>
            <PersonOutlinedIcon />
          </IconButton>
          <Menu
            className="userMenu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <Box sx={{ padding: "6px 16px" }}>
              <Typography
                variant="h4"
                color="initial"
                sx={{
                  fontWeight: "bold",
                  color: colors.themeDarkGrey[100],
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  overflow: "auto"
                }}
              >
                {user.firstName} {user.lastName}
              </Typography>
              <Typography
                variant="span"
                color={colors.greenAccent[500]}
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  overflow: "auto"
                }}
              >
                {user.email}
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            {user?.userType === "admin" && (
              <MenuItem
                sx={{ color: colors.themeDarkGrey[100] }}
                onClick={() => {
                  navigate("profile");
                  handleClose();
                }}
              >
                <LocationOnIcon size="small" sx={{ mr: 1 }} />
                Address
              </MenuItem>
            )}
            <MenuItem
              sx={{ color: colors.themeDarkGrey[100] }}
              onClick={() => {
                handleClickOpen();
              }}
            >
              <LockIcon size="small" sx={{ mr: 1 }} />
              Change Password
            </MenuItem>
            <MenuItem sx={{ color: colors.themeDarkGrey[100] }} onClick={() => setOpenLogout(true)}>
              <PowerSettingsNewIcon size="small" sx={{ mr: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box>
        <ChangePassword openDialog={openDialog} handleClose={handleClose} />
        <LogoutDialog
          openLogout={openLogout}
          handleClickLogoutOpen={handleClickLogoutOpen}
          handleLogoutClose={handleLogoutClose}
          onHandleLogout={onHandleLogout}
        />
      </Box>
    </>
  );
};

export default Topbar;
