import { useTheme } from "@emotion/react";
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ErrorMSG from "../../components/ErrorMSG";
import MyDialog from "../../components/MyDialog";
import { tokens } from "../../theme";
import { passwordRegex } from "../../utils/regex";
import { addConfirmPassword } from "../login/redux/action";

export default function ChangePassword({ openDialog, handleClose }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // main state to store the payload
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      previousPassword: "",
      password: "",
      confirmPassword: ""
    }
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      await dispatch(addConfirmPassword(data)).then(async (data) => {
        if (data && data?.payload?.status) {
          toast.success(data.payload.message);
          reset({ previousPassword: "", password: "", confirmPassword: "" });
          handleClose();
        } else {
          toast.error(data.error);
          handleClose();
        }
      });
    }
  };

  return (
    <>
      <MyDialog
        maxWidth="sm"
        open={openDialog}
        handleClose={() => {
          handleClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">Change Password</DialogTitle>
          <DialogContent>
            <Box m="10px">
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="password"
                      size="small"
                      label={
                        <p className="my-0">
                          Current Password <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("previousPassword", { required: true })}
                    />
                    {errors.previousPassword && errors.previousPassword.type === "required" && (
                      <ErrorMSG text="Current Password is required" />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="password"
                      size="small"
                      label={
                        <p className="my-0">
                          New Password <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("password", { required: true, pattern: passwordRegex })}
                    />
                    {errors.password && errors.password.type === "required" && (
                      <ErrorMSG text="New Password is required" />
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <ErrorMSG text="Password at least 1 uppercase, 1 lowercase, 1 special character, 1 number, Min 8, Max 30" />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="password"
                      size="small"
                      label={
                        <p className="my-0">
                          Confirm New Password <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("confirmPassword", {
                        required: true,
                        validate: (val) => control._formValues.password === val
                      })}
                    />
                    {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                      <ErrorMSG text="Confirm New Password is required" />
                    )}
                    {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                      <ErrorMSG text="Your Confirm password does not match" />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
                reset({ previousPassword: "", password: "", confirmPassword: "" });
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {/* {isLoading ? "Saving..." : "Save"} */}
              Save
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </>
  );
}
