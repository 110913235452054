import { OrderMap } from "./type";

// category intial state
const initialProducts = {
  orders: [],
  isLoading: false,
  error: null,
  success: false,
  total: null,
  customers: [],
  isSearchLoading: false,
  singleOrder: null,
  isLoadingFind: false,
  isLoadingShip: false
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case OrderMap.ORDER_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case OrderMap.ORDER_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.ORDER_ADD_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.ORDER_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case OrderMap.ORDER_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.ORDER_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.ORDER_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case OrderMap.ORDER_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.ORDER_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.ORDER_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case OrderMap.ORDER_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.CUSTOMER_DROPDOWN_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.CUSTOMER_DROPDOWN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customers: action.payload.data
      };
    }
    case OrderMap.CUSTOMER_DROPDOWN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.FIND_INVENTORY_REQUEST:
      return { ...state, isLoadingFind: true };
    case OrderMap.FIND_INVENTORY_SUCCESS: {
      return {
        ...state,
        isLoadingFind: false
      };
    }
    case OrderMap.FIND_INVENTORY_FAIL:
      return { ...state, isLoadingFind: false, error: action.error };
    case OrderMap.SEARCH_INVENTORY_REQUEST:
      return { ...state, isSearchLoading: true };
    case OrderMap.SEARCH_INVENTORY_SUCCESS: {
      return {
        ...state,
        isSearchLoading: false
      };
    }
    case OrderMap.SEARCH_INVENTORY_FAIL:
      return { ...state, isSearchLoading: false, error: action.error };

    case OrderMap.SINGLE_ORDER_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.SINGLE_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        singleOrder: action.payload.data
      };
    }

    case OrderMap.SINGLE_ORDER_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.PAYMENT_ADD_REQUEST:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.PAYMENT_ADD_SUCCESS:
      return { ...state, isLoading: true };
    case OrderMap.PAYMENT_ADD_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }
    case OrderMap.FEDEX_ADD_SHIPMENT_REQUEST:
      return { ...state, isLoadingShip: true };
    case OrderMap.FEDEX_ADD_SHIPMENT_SUCCESS:
      return { ...state, isLoadingShip: false };
    case OrderMap.FEDEX_ADD_SHIPMENT_FAIL:
      return { ...state, isLoadingShip: false };
    case OrderMap.FEDEX_GET_QUOTES_REQUEST:
      return { ...state, isLoadingShip: true };
    case OrderMap.FEDEX_GET_QUOTES_SUCCESS:
      return { ...state, isLoadingShip: false };
    case OrderMap.FEDEX_GET_QUOTES_FAIL:
      return { ...state, isLoadingShip: false };
    default:
      return state;
  }
};
