// main root endpoint
export const API_ROOT = process.env.REACT_APP_BACKEND_URI;

// login root endpoint
const loginEndpoint = "api/v2/auth/login";
const authCheckEndpoint = "api/v2/auth/check";
const authRefreshCaptchaEndpoint = "api/v2/auth/refresh-captcha";
const authCheckCaptchaEndpoint = "api/v2/auth/check-captcha";
const changePasswordEndpoint = "api/v2/auth/changePassword";

// category endpoints
const categoryEndpoint = "api/v2/category/list";
const addCategoryEndpoint = "api/v2/category/create";
const editCategoryEndpoint = "api/v2/category/edit";
const deleteCategoryEndpoint = "api/v2/category/delete";
const getSingleCategoryEndpoint = "/api/v2/category/get";
const getCategoryTreeEndpoint = "api/v2/category/category-tree";

// tax endpoints
const taxEndpoint = "api/v2/tax/get";
const addTaxEndpoint = "api/v2/tax/add";
const editTaxEndpoint = "api/v2/tax/edit";
const getTaxesEndpoint = "api/v2/tax/dropdown";
const deleteTaxEndpoint = "api/v2/tax/delete";

// product endpoints
const productsEndpoint = "api/v2/product/list";
const addProductEndpoint = "api/v2/product/create";
const editProductEndpoint = "api/v2/product/edit";
const getProductEndpoint = "api/v2/product/show";
const deleteProductEndpoint = "api/v2/product/delete";

// user endpoints
const usersEndpoint = "api/v2/user/list";
const addUserEndpoint = "api/v2/user/create";
const editUserEndpoint = "api/v2/user/edit";
const getUserEndpoint = "api/v2/user/get";
const deleteUserEndpoint = "api/v2/user/delete";
const userRoleEndpoint = "api/v2/user/listrole";
const assignRoleEndpoint = "api/v2/user/assignrole";

// customer endpoints
const customerEndpoint = "api/v2/customer/list";
const addCustomerEndpoint = "api/v2/customer/create";
const editCustomerEndpoint = "api/v2/customer/edit";
const deleteCustomerEndpoint = "api/v2/customer/delete";
const getCustomerEndpoint = "api/v2/customer/show";
const editCustomerAddressEndpoing = "api/v2/customer/addressedit";

// single product endpoints
const singleProductEndpoint = "api/v2/subProduct/list";
const addSingleProductEndpoint = "api/v2/subProduct/create";
const editSingleEndpoint = "api/v2/subProduct/edit";
const getSingleProductEndpoint = "api/v2/user/get";
const deleteSingleProductEndpoint = "api/v2/subProduct/delete";

// vendor endpoints
const vendorsEndpoint = "api/v2/vendor/list";
const addVendorEndpoint = "api/v2/vendor/add";
const editVendorEndpoint = "api/v2/vendor/edit";
const deleteVendorEndpoint = "api/v2/vendor/delete";

// Inventory endpoints
const inventoryEndpoint = "api/v2/inventory/list";
const addInventoryEndpoint = "api/v2/inventory/create";
const editInventoryEndpoint = "api/v2/inventory/edit";
const deleteInventoryEndpoint = "api/v2/inventory/delete";
const singleInventoryEndpoint = "api/v2/inventory/get";
const lastInventoryEndpoint = "api/v2/subProduct/showInventory";
const searchInventoryEndpoint = "api/v2/order/search-inventory";

// Product list and subproduct list for inventory
const productlistEndpoint = "api/v2/product/dropdown";
const subproductlistEndpoint = "api/v2/subProduct/dropdown";
const vendorlistEndpoint = "api/v2/vendor/dropdown";

// Order endpoints
const ordersEndpoint = "api/v2/order/list";
const addOrderEndpoint = "api/v2/order/create";
const editOrderEndpoint = "api/v2/order/edit";
const deleteOrderEndpoint = "api/v2/order/delete";
const updateOrderStatusEndpoint = "api/v2/order/update-status";
const customerDropdownEndpoint = "api/v2/user/dropdown";
const findInventoryEndpoint = "api/v2/order/find-inventory";
const singleOrderEndpoint = "/api/v2/order/view";
const addPaymentEndpoint = "api/v2/orderPayment/create";
const editPaymentEndpoint = "api/v2/orderPayment/edit";
const clearPaymentEndpoint = "api/v2/orderPayment/confirmed-payment";

const viewPDFEndPoint = "/api/v2/order/view";

// All Module Status Endpoint
const editStatusEndpoint = "api/v2/status";

// product and sub-product delete Endpoint
const deleteAllModuleProductEndpoint = "api/v2/product/delete-image";

//dashboard details
const dashboardOneEndpoint = "api/v2/dashboard/dashboard-data";

// notification
const notificationOneEndpoint = "api/v2/notification/get";

//company names endPoint
const allCompanyDetailsEndPoint = "api/v2/user/dropdown";

// const fedexTokenEndPoint = "oauth/token";
const fedexTokenEndPoint = "api/v2/order/fedex-auth-token";
const shipmentsEndPoint = "api/v2/order/fedex-add-shipping";
const getQuotesEndPoint = "api/v2/order/get-quotes";
const getAdminAddressEndpont = "api/v2/customer/get-admin-address";

// all api end points
export const API_END_POINTS = {
  LOGIN: loginEndpoint,
  authCheck: authCheckEndpoint,
  authRefreshCaptcha: authRefreshCaptchaEndpoint,
  authCheckCaptcha: authCheckCaptchaEndpoint,
  changePassword: changePasswordEndpoint,
  categories: categoryEndpoint,
  addCategory: addCategoryEndpoint,
  editCategory: editCategoryEndpoint,
  deleteCategoryEndpoint: deleteCategoryEndpoint,
  treeCategory: getCategoryTreeEndpoint,
  taxs: taxEndpoint,
  deleteTax: deleteTaxEndpoint,

  addTax: addTaxEndpoint,
  editTax: editTaxEndpoint,
  getTaxesEndpoint: getTaxesEndpoint,
  getSingleCategoryEndpoint: getSingleCategoryEndpoint,

  products: productsEndpoint,
  addProduct: addProductEndpoint,
  editProduct: editProductEndpoint,
  getProduct: getProductEndpoint,
  deleteProduct: deleteProductEndpoint,

  singleProduct: singleProductEndpoint,
  addSingleProduct: addSingleProductEndpoint,
  editSingleProduct: editSingleEndpoint,
  getSingleProduct: getSingleProductEndpoint,
  deleteSingleProduct: deleteSingleProductEndpoint,

  users: usersEndpoint,
  addUser: addUserEndpoint,
  editUser: editUserEndpoint,
  getUser: getUserEndpoint,
  deleteUser: deleteUserEndpoint,
  userRole: userRoleEndpoint,
  assignRole: assignRoleEndpoint,

  customer: customerEndpoint,
  addCustomer: addCustomerEndpoint,
  editCustomer: editCustomerEndpoint,
  deleteCustomer: deleteCustomerEndpoint,
  getCustomer: getCustomerEndpoint,
  editCustomerAddress: editCustomerAddressEndpoing,

  vendors: vendorsEndpoint,
  addVendor: addVendorEndpoint,
  editVendor: editVendorEndpoint,
  deleteVendor: deleteVendorEndpoint,

  inventory: inventoryEndpoint,
  addInventory: addInventoryEndpoint,
  editInventory: editInventoryEndpoint,
  deleteInventory: deleteInventoryEndpoint,
  singleInventory: singleInventoryEndpoint,

  productlist: productlistEndpoint,
  subproductlist: subproductlistEndpoint,
  vendorlist: vendorlistEndpoint,
  lastInventory: lastInventoryEndpoint,

  orders: ordersEndpoint,
  addOrder: addOrderEndpoint,
  editOrder: editOrderEndpoint,
  deleteOrder: deleteOrderEndpoint,
  singleOrder: singleOrderEndpoint,
  updateOrderStatus: updateOrderStatusEndpoint,
  customerDropdown: customerDropdownEndpoint,
  findInventory: findInventoryEndpoint,
  searchInventory: searchInventoryEndpoint,

  editStatusEndpoint: editStatusEndpoint,

  deleteProductFromAPI: deleteAllModuleProductEndpoint,

  addPayment: addPaymentEndpoint,
  editPayment: editPaymentEndpoint,
  clearPayment: clearPaymentEndpoint,
  pdfView: viewPDFEndPoint,

  fetchDashboard1: dashboardOneEndpoint,
  fetchNotification: notificationOneEndpoint,

  fetchCompanies: allCompanyDetailsEndPoint,

  fedexToken: fedexTokenEndPoint,
  addShipments: shipmentsEndPoint,
  getAdminAddress: getAdminAddressEndpont,
  getQuotes: getQuotesEndPoint
};
