import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styles } from "../utils/custom";
import styled from "@emotion/styled";
import { customerType } from "../utils/CustomerType";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { tokens } from "../theme";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";
import VerticalSlider from "./VerticalSlider";

export default function InventoryFile({ appendInventory }) {
  console.log(appendInventory, "appendInventory");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
  });

  return (
    <div>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className="product-card" sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4">Create Sub-Product</Typography>
                <Button variant="outlined" onClick={() => appendInventory()}>
                  <AddIcon sx={{ fontSize: "14px" }} />
                </Button>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label={
                    <p className="my-0">
                      Sub Product Name <span className="danger mr-0">*</span>
                    </p>
                  }
                  // {...register("buyPrice", {
                  // required: true,
                  // pattern: priceRegex
                  // })}
                />
                {/* {errors.buyPrice && errors.buyPrice.type === "required" && (
    <ErrorMSG text="Buy Price is required" />
    )}
    {errors.buyPrice && errors.buyPrice.type === "pattern" && <ErrorMSG text="Buy Price not valid" />} */}
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  label={
                    <p className="my-0">
                      Min Stock Alert <span className="danger mr-0">*</span>
                      <Tooltip
                        title={
                          <>
                            <span>
                              {" "}
                              Enter the quantity to receive a notification when the stock level drops below it.
                            </span>
                          </>
                        }
                      >
                        <IconButton sx={{ p: 0.5 }}>
                          <ErrorOutlineIcon size="small" sx={{ fontSize: "20px", mb: "2px", color: "#f44336" }} />
                          {/* <QuestionMarkIcon size="small" sx={{ fontSize: "15px", mb: 0.5 }} /> */}
                        </IconButton>
                      </Tooltip>
                    </p>
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-6">
                  Select Status <span className="danger mr-0">*</span>
                </label>

                <Select
                  styles={styles}
                  defaultValue={""}
                  value={""}
                  size="small"
                  theme={(x) => ({ ...x, borderRadius: 3 })}
                  className="react-select-no-outline reactSelectFocus z-index-5"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder="Select Vendor"
                  noOptionsMessage={() => "Select Vendor"}
                  onChange={(e) => {
                    // onChange(e);
                  }}
                  // options={vendorsOption}
                  isSearchable={true}
                />
              </FormControl>

              {/* <FormControl fullWidth>
    <InputLabel id="demo-simple-select-helper-label">Select Status</InputLabel>
    <Select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={age}
    label="Select Status"
    size="small"
    onChange={handleChange}
    >
    <MenuItem value="">
    <em>None</em>
    </MenuItem>
    <MenuItem value={"Active"}>Active</MenuItem>
    <MenuItem value={"Inactive"}>Inactive</MenuItem>
    </Select> */}
              {/* <FormHelperText>With label + helper text</FormHelperText> */}
              {/* </FormControl> */}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box>
                <VerticalSlider />
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              {/* <Typography id="non-linear-slider" gutterBottom>
                Upload Image:
              </Typography> */}
              <Button sx={{ py: 1 }} component="label" fullWidth variant="outlined" startIcon={<AttachFileIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4">Add Inventory</Typography>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-6">
                  Select Vendor <span className="danger mr-0">*</span>
                </label>

                <Select
                  styles={styles}
                  defaultValue={""}
                  value={""}
                  size="small"
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-5"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder="Select Vendor"
                  noOptionsMessage={() => "Select Vendor"}
                  onChange={(e) => {
                    // onChange(e);
                  }}
                  // options={vendorsOption}
                  isSearchable={true}
                />
              </FormControl>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  label={
                    <p className="my-0">
                      Bulk Stock <span className="danger mr-0">*</span>
                      {/* <Tooltip
                        title={
                          <>
                            <span>Enter the quantity of product purchased from vendor.</span>
                          </>
                        }
                      >
                        <IconButton sx={{ p: 0.5 }}>
                          <ErrorOutlineIcon size="small" sx={{ fontSize: "20px", mb: "2px", color: "#f22336" }} />
                          <QuestionMarkIcon size="small" sx={{ fontSize: "15px", mb: 0.5 }} />
                        </IconButton>
                      </Tooltip> */}
                    </p>
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label={
                    <p className="my-0">
                      Buy Price <span className="danger mr-0">*</span>
                    </p>
                  }
                  // {...register("buyPrice", {
                  // required: true,
                  // pattern: priceRegex
                  // })}
                />
                {/* {errors.buyPrice && errors.buyPrice.type === "required" && (
    <ErrorMSG text="Buy Price is required" />
    )}
    {errors.buyPrice && errors.buyPrice.type === "pattern" && <ErrorMSG text="Buy Price not valid" />} */}
              </FormControl>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Grid container>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      sx={{ borderRadius: "4px 0 0 4px" }}
                      // disabled={disabledBarcode}
                      className="inputGroupButtonCustomInputs"
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Stock Barcode <span className="danger mr-0">*</span>
                        </p>
                      }
                      // InputLabelProps={{ shrink: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <button type="button" className="text-capitalize inputGroupButtonCustom" onClick={() => {}}>
                    Generate Barcode
                  </button>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}></Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}></Grid> */}

            {customerType?.map((cusType, i) => {
              return (
                <Grid key={i} item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Grid container>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                      <label className="text-capitalize inputGroupButtonLabels">{cusType}</label>
                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                      <FormControl>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          className="inputGroupButtonInputs"
                          InputLabelProps={{ shrink: true }}
                          label={
                            <p className="my-0">
                              Sale Price <span className="danger mr-0">*</span>
                            </p>
                          }
                          // {...register(`salePrice.${cusType}`, {
                          // required: true,
                          // pattern: priceRegex
                          // })}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* {errors.salePrice &&
    errors.salePrice[cusType] &&
    errors.salePrice[cusType]?.type === "required" && (
    <ErrorMSG text={`Sale(${cusType}) Price is required`} />
    )}
    {errors.salePrice &&
    errors.salePrice[cusType] &&
    errors.salePrice[cusType]?.type === "pattern" && (
    <ErrorMSG text={`Sale(${cusType}) Price not valid`} />
    )} */}
                </Grid>
              );
            })}
            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
    <TextField
    id="outlined-basic"
    label="Short Description"
    variant="outlined"
    size="small"
    fullWidth
    />
    </Grid> */}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ float: "right" }}>
                <Button variant="outlined" onClick={() => {}} sx={{ mr: "6px" }}>
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={[
                    {
                      "&:hover": {
                        bgcolor: colors.themeSecondaryBlueColor[100],
                        color: colors.white[100]
                      }
                    },
                    { bgcolor: colors.themeSecondaryBlueColor[100] }
                  ]}
                >
                  Save{" "}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
}
